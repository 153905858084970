import { fetchEventReportsUrl } from 'store/paths';

// Import helpers
import nodeApisAxios from 'services/nodeApisAxiosFactory';

export const fetchEventReports =
	({ queryParams, cancelToken }) =>
	async () => {
		let axios = nodeApisAxios();

		try {
			const { data } = await axios.get(
				fetchEventReportsUrl(queryParams),
				cancelToken
			);

			const { data: byDate, byPhoneNumber } = data || {};

			const result = { data: [byDate, byPhoneNumber] };

			return Promise.resolve({ data: result });
		} catch (error) {
			return Promise.reject(error);
		}
	};
